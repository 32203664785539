import React, { FC } from 'react';
import classes from './Header.module.scss';
import Navbar from '../Navbar/Navbar';
import Hero, { HeroType } from '../Hero/Hero';
import { navigate, PageProps } from 'gatsby';
import classNames from 'classnames';
import Button from '../Button/Button';

interface HeaderProps extends PageProps {
  page: HeroType;
  title?: string;
  subtitle?: string;
  description?: string;
  descriptionRedirect?: string;
}

const Header: FC<HeaderProps> = ({
  page,
  title,
  subtitle,
  description,
  path,
  descriptionRedirect,
}) => {
  return (
    <Hero page={page}>
      <div>
        <Navbar pathname={path} />
        <div
          className={classNames(classes.TitleContainer, {
            [classes.About]: page === 'About',
          })}
        >
          <div className={classes.Title}>{title}</div>
          <div className={classes.SubTitle}>{subtitle}</div>
          {description && (
            <div className={classes.Description}>
              <Button onClick={() => navigate(descriptionRedirect)}>
                {description}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Hero>
  );
};

export default Header;
