import React, { FC } from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.css';
import { Col, Row } from 'react-bootstrap';

import classes from './about.module.scss';
import '../styles/Index.scss';

import Footer from '../components/Footer/Footer';
import {
  AboutPageCaption,
  OtherLinks,
  SeoCaption,
  Socials,
  ValuesItems,
} from '../constants';
import Header from '../components/Header/Header';
import Hero, { HeroType } from '../components/Hero/Hero';
import telekomLogo from '../images/partners/telekom.png';
import skbLogo from '../images/partners/skb.png';
import generaliLogo from '../images/partners/generali.png';
import Button from '../components/Button/Button';
import SEO from '../components/SEO/SEO';
import ValuesItem, {
  ValuesItemInterface,
} from '../components/ValuesItem/ValuesItem';

const About: FC<PageProps> = (props) => {
  return (
    <main>
      <SEO title={SeoCaption.ABOUT} />
      <Header
        page={HeroType.ABOUT}
        {...props}
        title={AboutPageCaption.HEADER_TITLE}
      />
      {/* First section */}
      <Row className={classes.FirstSection}>
        <Col md={12} lg={5} className={classes.FirstLeftColumn}>
          {AboutPageCaption.FIRST_SECTION_LEFT_COLUMN}
        </Col>
        <Col />
      </Row>
      {/* Second section */}
      <div className={classes.GreySection}>
        <Hero page={HeroType.GREY_BACKGROUND} />
        <Row className={classes.GreyContainer}>
          <Col xs={12} className={classes.TopGreyRow}>
            <p>{AboutPageCaption.SECOND_SECTION_TITLE}</p>
          </Col>
          <Col xs={12} className={classes.BottomGreyRow}>
            <Row className={classes.ValuesWrapper}>
              {ValuesItems.map((item: ValuesItemInterface, i: number) => (
                <Col sm={12} md={4} key={i} className={classes.ValuesItem}>
                  <ValuesItem item={item} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
      {/* Third section */}
      <Hero page={HeroType.ABOUT_SUBSECTION_1} />
      <Row className={classes.SecondSection}>
        <Col md={12} lg={5} className="p-0">
          <div className={classes.SecondSectionLeftColumnTitle}>
            {AboutPageCaption.THIRD_SECTION_LEFT_COLUMN_TITLE}
          </div>
          <div className={classes.SecondSectionLeftColumnDescription}>
            {AboutPageCaption.THIRD_SECTION_LEFT_COLUMN_DESCRIPTION}
          </div>
        </Col>
        <Col md={1} />
        <Col md={12} lg={6} className={classes.SecondSectionRightColumn}>
          <div className={classes.SecondSectionRightColumnTitle}>
            {AboutPageCaption.THIRD_SECTION_RIGHT_COLUMN_TITLE}
          </div>
          <div className={classes.Divider} />
          <div className={classes.ImageContainer}>
            <a href={Socials.TELEKOM} target={'_blank'} rel="noreferrer">
              <img
                src={telekomLogo}
                alt={'Telekom logo'}
                className={classes.Image1}
              />
            </a>
            <a href={Socials.SKB} target={'_blank'} rel="noreferrer">
              <img src={skbLogo} alt={'SKB logo'} className={classes.Image2} />
            </a>
            <a href={Socials.GENERALI} target={'_blank'} rel="noreferrer">
              <img
                src={generaliLogo}
                alt={'Generali logo'}
                className={classes.Image3}
              />
            </a>
          </div>
          <div className={classes.Divider} />
          <div className={classes.SecondSectionRightColumnDescription}>
            {AboutPageCaption.THIRD_SECTION_RIGHT_COLUMN_DESCRIPTION}
          </div>
          <Button onClick={() => navigate(OtherLinks.CONTACT_US)}>
            {AboutPageCaption.THIRD_SECTION_RIGHT_COLUMN_BUTTON}
          </Button>
        </Col>
      </Row>
      {/* Fourth section */}
      <div className={classes.GreySection}>
        <Hero page={HeroType.GREY_BACKGROUND} />
        <Row className={classes.GreyContainer}>
          <Col sm={12} md={5} className={classes.LeftGreyColumn}>
            <p>{AboutPageCaption.LAST_SECTION_LEFT_COLUMN}</p>
          </Col>
          <Col md={1} />
          <Col sm={12} md={6} className={classes.RightGreyColumn}>
            <p>{AboutPageCaption.LAST_SECTION_RIGHT_COLUMN_P1}</p>
            <p>{AboutPageCaption.LAST_SECTION_RIGHT_COLUMN_P2}</p>
            <p>{AboutPageCaption.LAST_SECTION_RIGHT_COLUMN_P3}</p>
            <Link
              className={classes.ContentButton}
              to={`${OtherLinks.CONTACT_US}?dropdown=${AboutPageCaption.JOIN_US_LINK}`}
            >
              {AboutPageCaption.LAST_SECTION_LINK}
            </Link>
          </Col>
        </Row>
      </div>
      <Footer />
    </main>
  );
};

export default About;
