import classNames from 'classnames';
import React, { FC } from 'react';
import classes from './ValuesItem.module.scss';

export interface ValuesItemInterface {
  title: string;
  description: string;
}

interface ValuesItemProps {
  item: ValuesItemInterface;
  className?: string;
}

const ValuesItem: FC<ValuesItemProps> = ({ item, className }) => {
  return (
    <div
      className={classNames({
        [className]: !!className,
        [classes.Wrapper]: true,
      })}
    >
      <div className={classes.Title}>{item.title}</div>
      <div className={classes.Description}>{item.description}</div>
    </div>
  );
};

export default ValuesItem;
